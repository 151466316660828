.post_body {
  display: block;
  outline: 5px solid black;
  border-radius: 40px;
  margin: 0 3vw 2vh 3vw;
  padding: 1vh;
  font-family: "Poppins";
  box-shadow: 0 8px 16px 0 gray;
}

.post_header {
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 1.7vh;
}

.post_header p {
  margin: 0;
}

.post_pfp {
  outline: 5px solid black;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 0.5em;
}

.post_user {
  display: block;
}

.post_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
}

.post_info {
  font-size: 1.5vw;
}

.post_caption {
  margin: 0;
}

.post_comments {
  margin: 0;
  color: gray;
}

.first_comment {
  margin: 0;
}
