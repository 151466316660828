@import url("https://fonts.googleapis.com/css?family=Poppins");

.header {
  font-family: "Poppins";
  font-style: normal;
  font-size: 1vw;
  font-weight: 900;
  display: inline-block;
  color: black;
  margin-bottom: 0;
}

.header.mobile {
  font-size: 4vw;
  font-weight: normal;
  margin-top: 0;
}

.tasks_div {
  border: 1px black;
  border-style: solid;
  border-radius: 50px;
  margin-top: 8vw;
  margin-left: 3vw;
  margin-right: 3vw;
  padding: 3vw;
  background-color: #dbf0f5;
}

.tasks_div.mobile {
  border: 4px black;
  border-style: solid;
  border-radius: 50px;
  margin-top: 8vw;
  margin-left: 3vw;
  margin-right: 3vw;
  padding: 3vw;
  background-color: #dbf0f5;
}

.tasks_div.all {
  background-color: #f9f6dc;
}

.icon {
  height: 3vw;
  width: 3vw;
}

.icon.mobile {
  height: 12vw;
  width: 12vw;
}

.taskDesc {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 2vw;
  font-weight: 900;
}
.taskDesc.mobile {
  font-size: 7vw;
}


.taskNum {
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 2vw;
  font-weight: bolder;
  margin-left: auto;
}

.taskNum.mobile {
  font-size: 6vw;
}
