@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
.HomeLayout_container__2Br5L {
    box-sizing: border-box;
    margin: auto;
    padding: auto;
    width: 70%;
    height: 100vh;
    overflow: auto;
}

.HomeLayout_container__2Br5L.HomeLayout_mobile__3I2Qj {
    height: 100%;
    width: 100%;
    padding: 0vw;
}

.HomeLayout_caption__2Jyvf {
    margin-top: 1vh;
    margin-bottom: 1vh;
    text-align: center;
}

.HomeLayout_caption__2Jyvf.HomeLayout_mobile__3I2Qj {
    margin-top: 3vh;
    margin-bottom: 3vh;
    text-align: center;
}


.HomeLayout_header__1QJtA {
    display: flex;
    justify-content: center;
    width: 1vh;
    height: 1vh;
    margin-left: 5%;
}

.HomeLayout_header__1QJtA.HomeLayout_mobile__3I2Qj {
    padding-top: 4vh;
}

.HomeLayout_block__1Ri8I {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2vh 5vh 15vh 5vh;
    position: relative;
    max-width: 100%;
    height: 80%;
}

.HomeLayout_block__1Ri8I.HomeLayout_mobile__3I2Qj {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    position: relative;
    overscroll-behavior: none;
    max-width: 100%;
    height: 75vh;
    padding: 2vh 2vh 2vh 2vh;
}
.src_font__cMdSM{
    font-family: 'Poppins', sans-serif;
}
.src_font__cMdSM {
    font-family: 'Poppins', sans-serif;
}

.Caption_caption__2AquB {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 0;
}

.Caption_mainTitle__2ICXX {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 3vw;
    font-weight: 900;
}

.Caption_mainTitle__2ICXX.Caption_mobile__38k1V {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 8vw;
    font-weight: 900;
}

.Caption_subTitle__8YIeS {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 1vw;
    font-weight: 500;
    color: #818181;
}

.Caption_subTitle__8YIeS.Caption_mobile__38k1V {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 4vw;
    font-weight: 500;
    color: #818181;
}
.BackArrow_backArrow__8sRK_ {
    display: flex;
    justify-content: center;
    width: 2%;
    height: auto;
    margin-top: 4vh;
    margin-left: 5%;
  }

  .BackArrow_backArrow__8sRK_.BackArrow_mobile__7lkwC {
    display: flex;
    justify-content: center;
    width: 4%;
    height: auto;
    margin-top: 4vh;
    margin-left: 5%;
  }
.NavBar_page__3sGkw {
    margin: 0;
    width: 100%;
    display: flex;
}

.NavBar_page__3sGkw.NavBar_mobile__1WjXJ {
    margin: 0;
    width: 100%;
}

.NavBar_navBar__1l3LP {
    box-shadow: 1px -5px 10px #D9D9D9;
    margin: auto;
    width: 100%;
    height: 15vh;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 100;
    bottom: 0;
}

.NavBar_navBar__1l3LP.NavBar_mobile__1WjXJ {
    height: 10vh;
}

/* @media screen and (min-width: 1000px) {
    .navBar {
        top: 0;
        height: 15vh;
    }
 } */

.NavBar_navItem__3GoXa.NavBar_mobile__1WjXJ {
    box-sizing: border-box;
    width: 33.333%;
    height: 100%;
    background-color: #ffffff;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: center;   
    margin-bottom: 20px;
    cursor: pointer;
}

.NavBar_navItem__3GoXa {
    box-sizing: border-box;
    width: 33.333%;
    height: 100%;
    background-color: #ffffff;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: center;   
    margin-bottom: 20px;
    cursor: pointer;
}

.NavBar_icon_div__kPY9e.NavBar_mobile__1WjXJ {
    text-align: center;
    display: flex;
    justify-content: center;
}

.NavBar_icon_div__kPY9e {
    text-align: center;
    display: flex;
    justify-content: center;
}
.Home_container__3YUuv {
    width: 70%;
    margin: auto;
    box-sizing: border-box;
    padding: 2vw 2vw 10vw 2vw;
}

.Home_container__3YUuv.Home_mobile__23t0- {
    width: 100%;
    box-sizing: border-box;
    padding: 2vw 2vw 10vw 2vw;
}

.Home_text_div__25w6h {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
}

.Home_text_div__25w6h.Home_first__wjKA1 {
    margin-top: 5vw;
}

.Home_text_div__25w6h.Home_second__RIye3 {
    margin-top: 3vw;
}
.Home_welcome__2dBSy {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 3vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.Home_welcome__2dBSy.Home_mobile__23t0- {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 7vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.Home_welcome__2dBSy.Home_family__3JzHp {
    color: black;
    font-weight: 900;
}

.Home_cruising__3sSYG {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 2vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.Home_cruising__3sSYG.Home_mobile__23t0- {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 6vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.Home_cruising__3sSYG.Home_color__2kDdg {
    color: #E3D150;
    font-weight: 900;
}

.Home_link_div__WExeL {
    text-align: center;
    margin-top: 8vw;
    margin-bottom: 2vw;
}

.Home_link__1UPcf {
    font-family: 'Poppins';
    display: block;
    color: #0198BA;
    font-size: 3vw;
    font-weight: 400;
}
.OnYourRadar_todo_div__3FGmO {
  border: 3px #0198ba;
  border-style: solid;
  border-radius: 50px;
  margin-top: 5vw;
  margin-left: 2vw;
  margin-right: 2vw;
  padding-left: 3vw;
  padding-bottom: 10vw;
}

.OnYourRadar_todo_div__3FGmO.OnYourRadar_mobile__2A1zM {
  border: 6px #0198ba;
  border-style: solid;
  border-radius: 50px;
  margin-top: 10vw;
  margin-left: 2vw;
  margin-right: 2vw;
  padding-left: 3vw;
  padding-bottom: 10vw;
}

.OnYourRadar_priorityParent__dnDyg{
  display: flex;
  justify-content: space-between;
}
.OnYourRadar_priorityBlock__21jeu{
  flex: 1 1;
  display: inline-block;
}

.OnYourRadar_priorityParent__dnDyg.OnYourRadar_mobile__2A1zM{
  display: block;
}

.OnYourRadar_priorityBlock__21jeu.OnYourRadar_mobile__2A1zM{
  display: block;
}

.OnYourRadar_radar__2MTpn.OnYourRadar_mobile__2A1zM {
  font-family: "Poppins";
  font-weight: 900;
  color: black;
  font-size: 3vw;
  margin-top: 3vw;
}
  
.OnYourRadar_radar__2MTpn {
  font-family: "Poppins";
  font-weight: 900;
  color: black;
  font-size: 2vw;
}

.OnYourRadar_priority__35hl6.OnYourRadar_mobile__2A1zM {
  font-family: "Poppins";
  font-weight: bolder;
  color: #8b5674;
  font-size: 3vw;
}

.OnYourRadar_priority__35hl6 {
  font-family: "Poppins";
  font-weight: bolder;
  color: #8b5674;
  font-size: 2vw;
}

.OnYourRadar_priority__35hl6.OnYourRadar_else__3XL5z {
  color: #e3d150;
}

.OnYourRadar_list__3K7Cu {
  font-family: 'Poppins';
  color: black;
  font-weight: 600;
  font-size: 1.7vw;
}

.OnYourRadar_list__3K7Cu.OnYourRadar_mobile__2A1zM {
  font-family: 'Poppins';
  color: black;
  font-weight: 600;
  font-size: 6vw;
}
.loader {
  height: 90vh;
  text-align: center;
  display: block;
  align-items: center;
}

.logo {
  margin-top: 70%;
  margin-bottom: 10%;
}


.PointsDisplay_pointsBlock__sPQO1 {
    margin-top: 4vw
}

.PointsDisplay_text_div__1rVGL {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
}

.PointsDisplay_name__3YyZA {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 2vw;
    margin: auto;
    color: #000000;
}

.PointsDisplay_name__3YyZA.PointsDisplay_mobile__2lBiq {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 5vw;
    margin: 1vh;
    color: #000000;
}


.PointsDisplay_points__3-cXz.PointsDisplay_mobile__2lBiq {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 4.2vw;
    margin: auto;
    display: inline-block;
    color: #000000;
}

.PointsDisplay_points__3-cXz {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 1.8vw;
    margin: auto;
    display: inline-block;
    color: #000000;
}

.PointsDisplay_points__3-cXz.PointsDisplay_text__2_PXN {
    font-weight: 600;
    color: #818181;
}

.PointsDisplay_progress_circle__2yaD8 {
    width: 20vw;
    height: 20vw;
    margin-top: 4vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
}

.PointsDisplay_progress_circle__2yaD8.PointsDisplay_mobile__2lBiq {
    width: 40vw;
    height: 40vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
    margin-left: auto;
    margin-right: auto;
}

.PointsDisplay_progress_circle_text__36xvD {
    font-family: 'Poppins';
    font-weight: 900;
    color: black;
    font-size: 2vw;
}

.PointsDisplay_progress_circle_text__36xvD.PointsDisplay_mobile__2lBiq {
    font-family: 'Poppins';
    font-weight: 900;
    color: black;
    font-size: 6vw;
}
.Login_content__3CYQZ {
  display: block;
  height: 100vh;
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
}

.Login_image__rq4q_.Login_logo__2C2Aj {
  width: 40%;
  height: auto;
  margin-left: 30%;
  object-fit: cover;
}

@media (orientation: portrait) {
  .Login_content__3CYQZ {
    margin-top: 0;
    width: 85vw;
  }
  .Login_image__rq4q_ {
    padding-top: 30%;
    width: 90%;
    max-height: auto;
  }
  .Login_header__32QFs.Login_description__3r5B4 {
    font-size: 1.5vh;
  }
  .Login_form_extras__1HG3_ {
    display: flex;
    align-items: center;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  }
}

.Login_image__rq4q_ {
  margin-left: auto;
  margin-right: auto;
  min-width: 50%;
  max-width: 80%;
  display: flex;
  justify-content: center;
}

.Login_header__32QFs {
  font-family: "Poppins";
}

.Login_header__32QFs.Login_title__jDi0S {
  font-size: 3.5vh;
  font: bolder;
  margin-bottom: 0;
}

.Login_header__32QFs.Login_description__3r5B4 {
  color: rgb(129, 129, 129);
  font-size: 1.8vh;
  margin-top: 1%;
}

.Login_login_form__3Y60v {
  margin-top: 5%;
  width: 100%;
}

.Login_login_form__3Y60v > label {
  font-family: "Poppins";
  font-size: 1.8vh;
  color: rgb(129, 129, 129);
}

.Login_login_form__3Y60v > input[type="email"] {
  background-color: rgb(234, 239, 241);
  width: 97%;
  height: 4vh;
  margin: 0;
  border-radius: 15px;
  border-width: 0;
  font-family: "Poppins";
  font-size: 2vh;
  padding-left: 15px;
}

.Login_login_form__3Y60v > input[type="password"] {
  background-color: rgb(234, 239, 241);
  width: 97%;
  height: 4vh;
  margin: 0;
  border-radius: 15px;
  border-width: 0;
  font-family: "Poppins";
  font-size: 2vh;
  padding-left: 15px;
}

.Login_form_extras__1HG3_ {
  display: flex;
  align-items: center;
}

.Login_form_extras__1HG3_ > input[type="checkbox"] {
  border-radius: 17.5px;
  height: 2vh;
  width: 2vh;
  background-color: rgb(234, 239, 241);
  margin-right: 10px;
}

.Login_form_extras__1HG3_ > input[type="checkbox"]:hover {
  cursor: pointer;
}

.Login_form_extras__1HG3_ > label {
  font-family: "Poppins";
  margin-left: 1px;
  margin-right: auto;
  font-size: 1.5vh;
}

.Login_form_extras__1HG3_ > label:hover {
  cursor: pointer;
}

.Login_form_extras__1HG3_.Login_desc__3wxWf {
  font-size: 1.5vh;
  font-family: "Poppins";
}

.Login_login_form__3Y60v > input[type="submit"] {
  padding-right: 1%;
  width: 99%;
  height: 6vh;
  font-size: 2vh;
  font-family: "Poppins";
  font-weight: bold;
  color: white;
  border-width: 0;
  border-radius: 20px;
  background-color: rgb(2, 152, 186);
  padding: 0;
}

.Login_login_form__3Y60v > input[type="submit"]:hover {
  cursor: pointer;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s;
  box-shadow: 0 6px 4px darkgray;
}

.Login_signup__1i9CL {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.5vh;
  font-family: "Poppins";
  margin-top: 0.5vh;
}

.Login_signup__1i9CL.Login_desc__3wxWf {
  color: rgb(129, 129, 129);
  font-family: "Poppins";
}

.Login_error__WN4FS {
  color: red;
  font-size: 1.6vh;
}

.SignUp_inputBlock__1KkxG {
    margin-bottom: 12px;
}

.SignUp_title__1GYdZ {
    margin-bottom: 12px;
    margin-top: 12px;
}

.SignUp_register__1jfD6 {
    margin-top: 36px;
}
.AuthInputBlock_inputBlock__3JBvD {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.AuthInputBlock_label__3qQ1G.AuthInputBlock_mobile__3ZHYq {
    font-family: "Poppins";
    font-size: 4vw;
    box-sizing: border-box;
    margin: 0;
    color: #818181;
}

.AuthInputBlock_input__2oP4-.AuthInputBlock_mobile__3ZHYq {
    font-family: "Poppins";
    box-sizing: border-box;
    width: 100%;
    height: 12vw;
    font-size: 4vw;
    border-radius: 20px;
    outline: none;
    border: none;
    padding: 5px; 

    background-color: #F4F4F4;
}

.AuthInputBlock_label__3qQ1G {
    font-family: "Poppins";
    font-size: 15px;
    box-sizing: border-box;
    margin: 0;
}

.AuthInputBlock_input__2oP4- {
    font-family: "Poppins";
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0 5px;
    background-color: #F4F4F4;
}
.AuthSelectBlock_selectBlock__qztaO.AuthSelectBlock_mobile__pSwlZ {
    width: 100%;
    height:  -webkit-fit-content;
    height:  -moz-fit-content;
    height:  fit-content;
}

.AuthSelectBlock_selectBlock__qztaO {
    width: 100%;
    height: 70px;
}

.AuthSelectBlock_label__1k2SZ.AuthSelectBlock_mobile__pSwlZ {
    font-family: "Poppins";
    font-size: 4vw;
    box-sizing: border-box;
    margin: 0;
    color: #818181;
}

.AuthSelectBlock_label__1k2SZ {
    font-family: "Poppins";
    font-size: 15px;
    box-sizing: border-box;
    margin: 0;
}

.AuthSelectBlock_selectInput__17P3V.AuthSelectBlock_mobile__pSwlZ{
    width: 100%;
    height: 12vw;
}
.AuthSelectBlock_selectInput__17P3V{
    
}
.AuthButton_button__FffVS {
    width: 50%;
    height: 5vh;
    background-color: #0198BA;
    color: white;
    font-family: "Poppins";
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
}

.AuthButton_button__FffVS.AuthButton_mobile__1-8ET {
    width: 80%;
    min-height: 5vh;
    background-color: #0198BA;
    color: white;
    font-family: "Poppins";
    font-size: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2vh;
    cursor: pointer;
    margin: 4vh;
}
.Upcoming_upcomingWrapper__2NN-C {
    align-items: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: inline;
    overflow: scroll;
    overscroll-behavior: none;
    border: 0;
    padding-bottom: 10vw;
}
.UpcomingComponent_upcomingWrapper__1jJ1Z {
    background-color: rgba(1, 152, 186, 0.15);
    border-radius: 50px;
}

.UpcomingComponent_header__2lvxb {
    display: flex;
}

.UpcomingComponent_title__EFyi7{
    font-style: normal;
    font-size: 1.7vw;
    font-weight: 500;
    margin: 0;
    color: #000000;
    width: 90%;
}

.UpcomingComponent_title__EFyi7.UpcomingComponent_mobile__2dAr6 {
    font-size: 7vw;
}

.UpcomingComponent_time__2H2_c{
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    color: #0198BA;
}

.UpcomingComponent_time__2H2_c.UpcomingComponent_mobile__2dAr6{
    font-size: 3vw;
}

.UpcomingComponent_content__2Ylwl{
    font-style: normal;
    font-weight: 300;
    font-size: 1.2vw;
    color: #8B5674;
    margin: 2;

    color: #818181;
}

.UpcomingComponent_content__2Ylwl.UpcomingComponent_mobile__2dAr6 {
    font-size: 3vw;
}
.CheckBox_checkBox__KU2wZ{
    display: inline-block;
    width: 5vh;
    height: 5vh;
    background: #E0E0E0;
    margin-left: auto; 
    margin-right: 0;
}

.CheckBox_checkBox__KU2wZ.CheckBox_mobile__3VaLU{
    display: inline-block;
    width: 4vh;
    height: 4vh;
    background: #E0E0E0;
    margin-left: auto; 
    margin-right: 0;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    border: 1px solid #ccc;
    outline: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background-color: #818181;
    border-color: #818181;
  }
.Roadmap_header__3BJTK {
  font-family: "Poppins";
  font-style: normal;
  font-size: 1vw;
  font-weight: 900;
  display: inline-block;
  color: black;
  margin-bottom: 0;
}

.Roadmap_header__3BJTK.Roadmap_mobile__1HyoN {
  font-size: 4vw;
  font-weight: normal;
  margin-top: 0;
}

.Roadmap_tasks_div__1MwVH {
  border: 1px black;
  border-style: solid;
  border-radius: 50px;
  margin-top: 8vw;
  margin-left: 3vw;
  margin-right: 3vw;
  padding: 3vw;
  background-color: #dbf0f5;
}

.Roadmap_tasks_div__1MwVH.Roadmap_mobile__1HyoN {
  border: 4px black;
  border-style: solid;
  border-radius: 50px;
  margin-top: 8vw;
  margin-left: 3vw;
  margin-right: 3vw;
  padding: 3vw;
  background-color: #dbf0f5;
}

.Roadmap_tasks_div__1MwVH.Roadmap_all__1vWFU {
  background-color: #f9f6dc;
}

.Roadmap_icon__19Q6w {
  height: 3vw;
  width: 3vw;
}

.Roadmap_icon__19Q6w.Roadmap_mobile__1HyoN {
  height: 12vw;
  width: 12vw;
}

.Roadmap_taskDesc__2ZHXK {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 2vw;
  font-weight: 900;
}
.Roadmap_taskDesc__2ZHXK.Roadmap_mobile__1HyoN {
  font-size: 7vw;
}


.Roadmap_taskNum__v_INf {
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 2vw;
  font-weight: bolder;
  margin-left: auto;
}

.Roadmap_taskNum__v_INf.Roadmap_mobile__1HyoN {
  font-size: 6vw;
}

body {
  margin: 0;
}

.Community_content__2JteW {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.Community_header__2lt3e {
  display: flex;
  align-items: center;
  width: 100%;
  height: 7vh;
  box-shadow: 0 8px 16px 0 gray;
  margin-bottom: 2vh;
  position: absolute;
  background-color: white;
}

.Community_create_post__2bmB- {
  width: 160px;
  height: 160px;
  background-color: #0298ba;
  box-shadow: 0 2px 16px 0 gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  right: 4vw;
  bottom: 10vh;
  z-index: 2;
}

.Community_posts__2K0KS {
  margin-top: 10vh;
}

.Community_create_post__2bmB- img {
  width: 70%;
}

.Community_create_post__2bmB-:hover {
  cursor: pointer;
}

.Community_create_post__2bmB- {
  cursor: pointer;
}

.CommunityHeader_content__2ovXy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.CommunityHeader_dropdown_wrapper__Fv--b {
  margin-left: 9vw;
}

.CommunityHeader_dropdown__3UMnM {
  width: auto;
  margin-left: auto;
  height: auto;
  font-size: 2.5vh;
  font-family: "Poppins";
  font-weight: 700;
  outline: none;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  text-align: center;
}

.CommunityHeader_dropdown__3UMnM option {
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CommunityHeader_arrow__1zini {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10%;
}

.CommunityHeader_arrow__1zini img {
  width: 40%;
}

.CommunityPost_post_body__1Jjtq {
  display: block;
  outline: 5px solid black;
  border-radius: 40px;
  margin: 0 3vw 2vh 3vw;
  padding: 1vh;
  font-family: "Poppins";
  box-shadow: 0 8px 16px 0 gray;
}

.CommunityPost_post_header__2xM_8 {
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 1.7vh;
}

.CommunityPost_post_header__2xM_8 p {
  margin: 0;
}

.CommunityPost_post_pfp__2k5oy {
  outline: 5px solid black;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 0.5em;
}

.CommunityPost_post_user__3YHsm {
  display: block;
}

.CommunityPost_post_content__2Y_1x {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
}

.CommunityPost_post_info__1VTHj {
  font-size: 1.5vw;
}

.CommunityPost_post_caption__1FOwy {
  margin: 0;
}

.CommunityPost_post_comments__2aKZI {
  margin: 0;
  color: gray;
}

.CommunityPost_first_comment__1AOME {
  margin: 0;
}

.ProfilePicture_content__ydgUW {
  border-color: black;
  height: 100%;
  overflow: hidden;
  border-width: 100px;
}

.ProfilePicture_content__ydgUW img {
  height: 100%;
}

.TaskDetails_content__n4M-A {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  /* overflow-x: hidden; */
}

.TaskDetails_header__111uo {
  display: block;
  background-color: white;
  color: black;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 90%;
}

.TaskDetails_back_arrow__30R20 {
  display: flex;
  justify-content: center;
  width: 5%;
  height: auto;
  margin-top: 4vh;
}

.TaskDetails_back_arrow__30R20 img {
  width: 100%;
}

.TaskDetails_header__111uo.TaskDetails_title__39qIR {
  width: auto;
  font-size: 1.5vh;
  font-weight: bold;
}

.TaskDetails_info__17v_j {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  font-size: 1.5em;
}

.TaskDetails_info__17v_j.TaskDetails_desc__1QUhV {
  width: 100%;
  color: #555555;
  margin-top: 4%;
}

.TaskDetails_info__17v_j.TaskDetails_status__2SMa_ {
  display: inline-flex;
  align-items: center; 
  width: 100%;
  margin-top: 4%;
  font-size: 2vh;
}

.TaskDetails_image__2lo6R {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}

.TaskDetails_image__2lo6R img {
  width: 40%;
}

.TaskDetails_resources__10mnw {
  width: 100%;
}

.TaskDetails_resources__10mnw.TaskDetails_title__39qIR {
  font-size: 2vh;
  font-weight: bolder;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.TaskDetails_resources__10mnw.TaskDetails_content__n4M-A {
  margin-left: auto;
  margin-right: auto;
}

.TaskResource_card_content__2b-Es {
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 30px #555555;
  border-radius: 15px;
  font-size: 1.7vh;
  margin-top: 20px;
  margin-bottom: 20px;
}

.TaskResource_card_info__2WLu2 {
  display: block;
  width: 60%;
}

.TaskResource_card_title__2p4Jh {
  width: 100%;
  padding: 40px 0 40px 40px;
  margin: 0;
  word-wrap: break-word;
}

.TaskResource_card_desc__2N1ub {
  padding: 0 0 40px 40px;
  width: 100%;
  margin: 0;
}

.TaskResource_card_img__1VnJS {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: auto;
}

.TaskResource_card_img__1VnJS img {
  width: 60%;
}

.AllTasks_header__27kpN {
    font-family: "Poppins";
    font-style: normal;
    font-size: 8vw;
    font-weight: 900;
    display: inline-block;
    color: black;
    margin-bottom: 0;
    margin-top: 6vw;
  }

  .AllTasks_header__27kpN.AllTasks_small__2PEU- {
    font-size: 4vw;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 4vw;
  }

  .AllTasks_back_arrow__3KDqi {
    display: flex;
    justify-content: center;
    width: 5%;
    height: auto;
    margin-top: 4vh;
    margin-left: 5%;
  }
  
  .AllTasks_back_arrow__3KDqi img {
    width: 100%;
  }
.AllTasksSection_entireSection__rjWe8 {
    border-radius: 50px;
    margin-top: 2vw;
    margin-bottom: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }

  .AllTasksSection_entireSection__rjWe8.AllTasksSection_mobile__1Uwdi {
    border-radius: 50px;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
  }

.AllTasksSection_childName__2kixo {
  font-family: "Poppins";
  font-weight:400;
  font-size: 1.5vw;
  margin: 0;
  margin-left: 6vh;
}

.AllTasksSection_childName__2kixo.AllTasksSection_mobile__1Uwdi {
  font-family: "Poppins";
  font-weight:400;
  font-size: 5vw;
  margin: 0;
  margin-left: 6vh;
}


.AllTasksSection_tasksSection__3_Prv {
  margin-top: 3vw;
  margin-bottom: 3vw;
  background-color: rgba(255, 255, 255, 0.5);
}
.TaskListItem_tasks_div__PMzGB {
    border-bottom: 1px solid black;
    padding: 2vw;
    display: flex;
    justify-content: flex-end;
  }

.TaskListItem_tasks_div__PMzGB.TaskListItem_mobile__2eyeF {
    border-bottom: 3px solid black;
    padding: 4vw;
    display: flex;
    justify-content: flex-end;
  }

.TaskListItem_taskName__giBPg {
  font-family: "Poppins";
  font-style: normal;
  font-size: 1.4vw;
  font-weight: 500;
  margin: 0;
}

.TaskListItem_taskName__giBPg.TaskListItem_mobile__2eyeF {
  font-family: "Poppins";
  font-style: normal;
  font-size: 5vw;
  font-weight: 500;
  margin: 0;
}

.TaskListItem_taskDate__1x75j.TaskListItem_mobile__2eyeF {
  font-family: "Poppins";
  font-weight:400;
  font-size: 4vw;
  color: #8B5674;
  margin: 0;
}

.TaskListItem_taskDate__1x75j {
  font-family: "Poppins";
  font-weight:400;
  font-size: 1.2vw;
  color: #8B5674;
  margin: 0;
}
