@import url("https://fonts.googleapis.com/css?family=Poppins");

.entireSection {
    border-radius: 50px;
    margin-top: 2vw;
    margin-bottom: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }

  .entireSection.mobile {
    border-radius: 50px;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
  }

.childName {
  font-family: "Poppins";
  font-weight:400;
  font-size: 1.5vw;
  margin: 0;
  margin-left: 6vh;
}

.childName.mobile {
  font-family: "Poppins";
  font-weight:400;
  font-size: 5vw;
  margin: 0;
  margin-left: 6vh;
}


.tasksSection {
  margin-top: 3vw;
  margin-bottom: 3vw;
  background-color: rgba(255, 255, 255, 0.5);
}