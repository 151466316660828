.content {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  /* overflow-x: hidden; */
}

.header {
  display: block;
  background-color: white;
  color: black;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 90%;
}

.back_arrow {
  display: flex;
  justify-content: center;
  width: 5%;
  height: auto;
  margin-top: 4vh;
}

.back_arrow img {
  width: 100%;
}

.header.title {
  width: auto;
  font-size: 1.5vh;
  font-weight: bold;
}

.info {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  font-size: 1.5em;
}

.info.desc {
  width: 100%;
  color: #555555;
  margin-top: 4%;
}

.info.status {
  display: inline-flex;
  align-items: center; 
  width: 100%;
  margin-top: 4%;
  font-size: 2vh;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}

.image img {
  width: 40%;
}

.resources {
  width: 100%;
}

.resources.title {
  font-size: 2vh;
  font-weight: bolder;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.resources.content {
  margin-left: auto;
  margin-right: auto;
}
