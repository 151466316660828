@value font from "../../index.module.css";

.font {
    font-family: 'Poppins', sans-serif;
}

.caption {
    height: fit-content;
    border: 0;
}

.mainTitle {
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 3vw;
    font-weight: 900;
}

.mainTitle.mobile {
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 8vw;
    font-weight: 900;
}

.subTitle {
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 1vw;
    font-weight: 500;
    color: #818181;
}

.subTitle.mobile {
    height: fit-content;
    font-family: "Poppins";
    font-style: normal;
    font-size: 4vw;
    font-weight: 500;
    color: #818181;
}