.card_content {
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 30px #555555;
  border-radius: 15px;
  font-size: 1.7vh;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card_info {
  display: block;
  width: 60%;
}

.card_title {
  width: 100%;
  padding: 40px 0 40px 40px;
  margin: 0;
  word-wrap: break-word;
}

.card_desc {
  padding: 0 0 40px 40px;
  width: 100%;
  margin: 0;
}

.card_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: auto;
}

.card_img img {
  width: 60%;
}
