@value font from "../../index.module.css";

.selectBlock.mobile {
    width: 100%;
    height:  fit-content;
}

.selectBlock {
    width: 100%;
    height: 70px;
}

.label.mobile {
    font-family: "Poppins";
    font-size: 4vw;
    box-sizing: border-box;
    margin: 0;
    color: #818181;
}

.label {
    font-family: "Poppins";
    font-size: 15px;
    box-sizing: border-box;
    margin: 0;
}

.selectInput.mobile{
    width: 100%;
    height: 12vw;
}
.selectInput{
    
}