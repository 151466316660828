.pointsBlock {
    margin-top: 4vw
}

.text_div {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
}

.name {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 2vw;
    margin: auto;
    color: #000000;
}

.name.mobile {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 5vw;
    margin: 1vh;
    color: #000000;
}


.points.mobile {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 4.2vw;
    margin: auto;
    display: inline-block;
    color: #000000;
}

.points {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 1.8vw;
    margin: auto;
    display: inline-block;
    color: #000000;
}

.points.text {
    font-weight: 600;
    color: #818181;
}

.progress_circle {
    width: 20vw;
    height: 20vw;
    margin-top: 4vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
}

.progress_circle.mobile {
    width: 40vw;
    height: 40vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
    margin-left: auto;
    margin-right: auto;
}

.progress_circle_text {
    font-family: 'Poppins';
    font-weight: 900;
    color: black;
    font-size: 2vw;
}

.progress_circle_text.mobile {
    font-family: 'Poppins';
    font-weight: 900;
    color: black;
    font-size: 6vw;
}