@import url("https://fonts.googleapis.com/css?family=Poppins");

.header {
    font-family: "Poppins";
    font-style: normal;
    font-size: 8vw;
    font-weight: 900;
    display: inline-block;
    color: black;
    margin-bottom: 0;
    margin-top: 6vw;
  }

  .header.small {
    font-size: 4vw;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 4vw;
  }

  .back_arrow {
    display: flex;
    justify-content: center;
    width: 5%;
    height: auto;
    margin-top: 4vh;
    margin-left: 5%;
  }
  
  .back_arrow img {
    width: 100%;
  }