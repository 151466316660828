.page {
    margin: 0;
    width: 100%;
    display: flex;
}

.page.mobile {
    margin: 0;
    width: 100%;
}

.navBar {
    box-shadow: 1px -5px 10px #D9D9D9;
    margin: auto;
    width: 100%;
    height: 15vh;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 100;
    bottom: 0;
}

.navBar.mobile {
    height: 10vh;
}

/* @media screen and (min-width: 1000px) {
    .navBar {
        top: 0;
        height: 15vh;
    }
 } */

.navItem.mobile {
    box-sizing: border-box;
    width: 33.333%;
    height: 100%;
    background-color: #ffffff;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: center;   
    margin-bottom: 20px;
    cursor: pointer;
}

.navItem {
    box-sizing: border-box;
    width: 33.333%;
    height: 100%;
    background-color: #ffffff;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: center;   
    margin-bottom: 20px;
    cursor: pointer;
}

.icon_div.mobile {
    text-align: center;
    display: flex;
    justify-content: center;
}

.icon_div {
    text-align: center;
    display: flex;
    justify-content: center;
}