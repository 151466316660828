.loader {
  height: 90vh;
  text-align: center;
  display: block;
  align-items: center;
}

.logo {
  margin-top: 70%;
  margin-bottom: 10%;
}
