@import url("https://fonts.googleapis.com/css?family=Poppins");

.tasks_div {
    border-bottom: 1px solid black;
    padding: 2vw;
    display: flex;
    justify-content: flex-end;
  }

.tasks_div.mobile {
    border-bottom: 3px solid black;
    padding: 4vw;
    display: flex;
    justify-content: flex-end;
  }

.taskName {
  font-family: "Poppins";
  font-style: normal;
  font-size: 1.4vw;
  font-weight: 500;
  margin: 0;
}

.taskName.mobile {
  font-family: "Poppins";
  font-style: normal;
  font-size: 5vw;
  font-weight: 500;
  margin: 0;
}

.taskDate.mobile {
  font-family: "Poppins";
  font-weight:400;
  font-size: 4vw;
  color: #8B5674;
  margin: 0;
}

.taskDate {
  font-family: "Poppins";
  font-weight:400;
  font-size: 1.2vw;
  color: #8B5674;
  margin: 0;
}