
@value font from "../../index.module.css";

.button {
    width: 50%;
    height: 5vh;
    background-color: #0198BA;
    color: white;
    font-family: "Poppins";
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
}

.button.mobile {
    width: 80%;
    min-height: 5vh;
    background-color: #0198BA;
    color: white;
    font-family: "Poppins";
    font-size: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2vh;
    cursor: pointer;
    margin: 4vh;
}