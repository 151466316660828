@import url('https://fonts.googleapis.com/css?family=Poppins');

.container {
    box-sizing: border-box;
    margin: auto;
    padding: auto;
    width: 70%;
    height: 100vh;
    overflow: auto;
}

.container.mobile {
    height: 100%;
    width: 100%;
    padding: 0vw;
}

.caption {
    margin-top: 1vh;
    margin-bottom: 1vh;
    text-align: center;
}

.caption.mobile {
    margin-top: 3vh;
    margin-bottom: 3vh;
    text-align: center;
}


.header {
    display: flex;
    justify-content: center;
    width: 1vh;
    height: 1vh;
    margin-left: 5%;
}

.header.mobile {
    padding-top: 4vh;
}

.block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2vh 5vh 15vh 5vh;
    position: relative;
    max-width: 100%;
    height: 80%;
}

.block.mobile {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    position: relative;
    overscroll-behavior: none;
    max-width: 100%;
    height: 75vh;
    padding: 2vh 2vh 2vh 2vh;
}