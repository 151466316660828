.content {
  display: block;
  height: 100vh;
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
}

.image.logo {
  width: 40%;
  height: auto;
  margin-left: 30%;
  object-fit: cover;
}

@media (orientation: portrait) {
  .content {
    margin-top: 0;
    width: 85vw;
  }
  .image {
    padding-top: 30%;
    width: 90%;
    max-height: auto;
  }
  .header.description {
    font-size: 1.5vh;
  }
  .form_extras {
    display: flex;
    align-items: center;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  }
}

.image {
  margin-left: auto;
  margin-right: auto;
  min-width: 50%;
  max-width: 80%;
  display: flex;
  justify-content: center;
}

.header {
  font-family: "Poppins";
}

.header.title {
  font-size: 3.5vh;
  font: bolder;
  margin-bottom: 0;
}

.header.description {
  color: rgb(129, 129, 129);
  font-size: 1.8vh;
  margin-top: 1%;
}

.login_form {
  margin-top: 5%;
  width: 100%;
}

.login_form > label {
  font-family: "Poppins";
  font-size: 1.8vh;
  color: rgb(129, 129, 129);
}

.login_form > input[type="email"] {
  background-color: rgb(234, 239, 241);
  width: 97%;
  height: 4vh;
  margin: 0;
  border-radius: 15px;
  border-width: 0;
  font-family: "Poppins";
  font-size: 2vh;
  padding-left: 15px;
}

.login_form > input[type="password"] {
  background-color: rgb(234, 239, 241);
  width: 97%;
  height: 4vh;
  margin: 0;
  border-radius: 15px;
  border-width: 0;
  font-family: "Poppins";
  font-size: 2vh;
  padding-left: 15px;
}

.form_extras {
  display: flex;
  align-items: center;
}

.form_extras > input[type="checkbox"] {
  border-radius: 17.5px;
  height: 2vh;
  width: 2vh;
  background-color: rgb(234, 239, 241);
  margin-right: 10px;
}

.form_extras > input[type="checkbox"]:hover {
  cursor: pointer;
}

.form_extras > label {
  font-family: "Poppins";
  margin-left: 1px;
  margin-right: auto;
  font-size: 1.5vh;
}

.form_extras > label:hover {
  cursor: pointer;
}

.form_extras.desc {
  font-size: 1.5vh;
  font-family: "Poppins";
}

.login_form > input[type="submit"] {
  padding-right: 1%;
  width: 99%;
  height: 6vh;
  font-size: 2vh;
  font-family: "Poppins";
  font-weight: bold;
  color: white;
  border-width: 0;
  border-radius: 20px;
  background-color: rgb(2, 152, 186);
  padding: 0;
}

.login_form > input[type="submit"]:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: 0.2s;
  box-shadow: 0 6px 4px darkgray;
}

.signup {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.5vh;
  font-family: "Poppins";
  margin-top: 0.5vh;
}

.signup.desc {
  color: rgb(129, 129, 129);
  font-family: "Poppins";
}

.error {
  color: red;
  font-size: 1.6vh;
}
