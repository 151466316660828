@import url('https://fonts.googleapis.com/css?family=Poppins');

.container {
    width: 70%;
    margin: auto;
    box-sizing: border-box;
    padding: 2vw 2vw 10vw 2vw;
}

.container.mobile {
    width: 100%;
    box-sizing: border-box;
    padding: 2vw 2vw 10vw 2vw;
}

.text_div {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
}

.text_div.first {
    margin-top: 5vw;
}

.text_div.second {
    margin-top: 3vw;
}
.welcome {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 3vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.welcome.mobile {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 7vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.welcome.family {
    color: black;
    font-weight: 900;
}

.cruising {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 2vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.cruising.mobile {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 6vw;
    margin: auto;
    display: inline-block;
    color: #A8A8A8;
}

.cruising.color {
    color: #E3D150;
    font-weight: 900;
}

.link_div {
    text-align: center;
    margin-top: 8vw;
    margin-bottom: 2vw;
}

.link {
    font-family: 'Poppins';
    display: block;
    color: #0198BA;
    font-size: 3vw;
    font-weight: 400;
}