@value font from "../../index.module.css";

.upcomingWrapper {
    composes: font;
    background-color: rgba(1, 152, 186, 0.15);
    border-radius: 50px;
}

.header {
    display: flex;
}

.title{
    font-style: normal;
    font-size: 1.7vw;
    font-weight: 500;
    margin: 0;
    color: #000000;
    width: 90%;
}

.title.mobile {
    font-size: 7vw;
}

.time{
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    color: #0198BA;
}

.time.mobile{
    font-size: 3vw;
}

.content{
    font-style: normal;
    font-weight: 300;
    font-size: 1.2vw;
    color: #8B5674;
    margin: 2;

    color: #818181;
}

.content.mobile {
    font-size: 3vw;
}