body {
  margin: 0;
}

.content {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 7vh;
  box-shadow: 0 8px 16px 0 gray;
  margin-bottom: 2vh;
  position: absolute;
  background-color: white;
}

.create_post {
  width: 160px;
  height: 160px;
  background-color: #0298ba;
  box-shadow: 0 2px 16px 0 gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  right: 4vw;
  bottom: 10vh;
  z-index: 2;
}

.posts {
  margin-top: 10vh;
}

.create_post img {
  width: 70%;
}

.create_post:hover {
  cursor: pointer;
}

.create_post {
  cursor: pointer;
}
